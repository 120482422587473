//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { bgimgUrl2 } from '/src/utils/config.js';	
import {
	Dialog
} from "vant";
import {
	getRegister,
	getSchoolInfo,
	receivePrizes
} from "../../api";
export default {
	name: "index",
	data() {
		return {
			bgimgUrl2:'',
			choose: true,
			regin: "",
			reginName: [], //区域数组
			selects: "",
			statusName: [], //学校数组
			grade: "", //所属年纪
			gradeName: [], //年级数组
			parentName: "", //家长姓名
			tutorName: "", //导师姓名
			userName: "", //姓名
			//tetherName:'',//老师姓名
			id: "",
			code: "",
			show: false, //奖品弹出框
			jiangpinURL: '' //奖品链接
		};
	},
	components: {},
	created() {
		this.bgimgUrl2 = bgimgUrl2;
		this.get_schoolInfo();
		// 参数 1 :老师 2：家长
		this.id = this.$route.query.id;
		if (this.id == 1) {
			this.choose = 1;
		} else if (this.id == 2) {
			this.choose = 2;
		} else if (this.id == 3) {
			this.choose = 3
		}
	},
	methods: {
		//验证用户信息
		startDati() {
			// this.show = true;
			let str = "";
			if (this.regin == '' || this.userName == '') {
				this.$toast.fail("请先填写个人信息");
			} else if (this.id == 2) {
				if (this.grade == '' || this.selects == '') {
					this.$toast.fail("请填写个人信息")
				} else {
					str = {
						identity: this.$route.query.id, // 区分老师与家长 1：老师，2：家长
						area: this.regin, //区域id
						school_id: this.selects, //学校id
						grade_id: this.grade,
						username: this.userName,
					}
					this.GetReceivePrizes(str)
				}
			} else if (this.id == 1) {
				if (this.selects == '') {
					this.$toast.fail("请填写个人信息")
				} else {
					str = {
						identity: this.$route.query.id, // 区分老师与家长 1：老师，2：家长
						area: this.regin, //区域id
						school_id: this.selects, //学校id
						username: this.userName,
					}
					this.GetReceivePrizes(str);
				}
			} else if (this.id == 3) {
				str = {
					identity: this.$route.query.id, // 区分老师与家长 1：老师，2：家长
					area: this.regin, //区域id
					username: this.userName,
				}
				this.GetReceivePrizes(str);
			}
		},
		// 领取奖励
		GetReceivePrizes(str) {
			receivePrizes(str).then(res => {
				if (res.data.code == 1) {
					this.jiangpinURL = res.data.data.image_url;
					this.show = true;
					// Dialog.confirm({
					//   title: '请复制链接去浏览器打开',
					//   message: url,
					// })
					//   .then(() => {
					// 	// window.location.href = res.data.data.image_url
					//   })
				} else {
					this.$toast.fail(res.data.msg)
				}
			})
		},
		//选择区域
		reginname() {
			var arr = this.reginName;
			for (var i = 0; i < arr.length; i++) {
				if (this.regin == arr[i].id) {
					this.statusName = arr[i].schoolArr;
					console.log(arr[i].schoolArr);
				}
			}
			console.log(this.regin);
		},
		// 选择学校
		schoolname() {
			var arr = this.statusName;
			for (var i = 0; i < arr.length; i++) {
				if (this.selects == arr[i].id) {
					this.gradeName = arr[i].children;
				}
			}
			console.log(this.selects);
		},
		//选择年级
		gradename() {
			console.log(this.grade);
		},
		//获取学校信息
		get_schoolInfo() {
			getSchoolInfo().then((res) => {
				var arr = res.data.data;
				let nameArr = arr.map((item) => {
					return {
						name: item.name,
						id: item.id,
						schoolArr: item.children
					};
				});
				//区域信息
				this.reginName = nameArr;
				//console.log(nameArr)
				console.log(arr);
			});
		},


	},
};
